import { Component, BaseComponent, namespace, Watch } from '@zento-lib/components';
import { CustomForms } from '@zento/modules/organism/CustomForms/CustomForms';
import { BlogWidget } from '@zento/modules/organism/Blog/BlogWidget/BlogWidget';
import { BlogCategories } from '@zento/modules/organism/Blog/BlogWidget/BlogCategories';
import { HomepageMainBanner } from '@zento/modules/molecule/Homepage/MainBanner/MainBanner';
import { HomePageUsp } from '@zento/modules/molecule/Homepage/Usp/Usp';
import { HomepageCategoriesList } from '@zento/modules/molecule/Homepage/CategoriesList/CategoriesList';
import { HomepageCategoriesListImage } from '@zento/modules/molecule/Homepage/CategoriesListImage/CategoriesListImage';
import { HomepageCategoriesListImageLarge } from '@zento/modules/molecule/Homepage/CategoryListImageLarge/CategoryListImageLarge';
import { HomepageCategoriesBanner } from '@zento/modules/molecule/Homepage/CategoriesBanner/CategoriesBanner';
import { HomepageShopByCategories } from '@zento/modules/molecule/Homepage/ShopByCategories/ShopByCategories';
import { HomepageStaticBanner } from '@zento/modules/molecule/Homepage/StaticBanner/StaticBanner';
import { HomepageSliderBanner } from '@zento/modules/molecule/Homepage/SliderBanner/SliderBanner';
import { TextBlock } from '@zento/modules/molecule/Homepage/TextBlock/TextBlock';
import { CmsBlock } from '@zento/modules/molecule/CmsBlock/CmsBlock';
import { HomepageProducts } from '@zento/modules/molecule/Homepage/ProductWidgets/Products';
import { Testimonials } from '@zento/modules/molecule/Homepage/Testimonials/Testimonials';
import { Instagram } from '@zento/modules/molecule/Homepage/Instagram/Instagram';
import { PageLinkWidget } from '@zento/modules/molecule/Homepage/PageLinkWidget/PageLinkWidget';
import { VideoWidget } from '@zento/modules/molecule/Homepage/VideoWidget/VideoWidget';
import { CmsPages } from '@zento/modules/molecule/Homepage/CmsPages/CmsPages';
import { Countdown } from '@zento/modules/molecule/Countdown/Countdown';
import { OverwriteStyle } from '@zento/modules/atom/OverwriteStyle/OverwriteStyle';
import type {
  BannerData,
  Usp,
  ShopByCategoriesData,
  TextBlockData,
  ProductsData,
  BannerHeroImage,
  TestimonialsData,
  InstagramData,
  CmsBlockData,
  FormData,
  PageWidgetData,
  VideoWidgetData,
  SliderBannerData,
  BlogArticlesData,
  BlogCategoriesData,
} from 'theme/stores/cms/types';
import type { CmsPageData } from 'theme/stores/cms/cms';

import { CmsPageMixin } from '../../mixins/pages/CmsPage';
import { IVSFUser, KEY as UserKey } from '../../@types/vsf/stores/user';
import { AppContextStore, KEY as AppContextKey } from '../../@types/zento/stores/applicationContext';

import type { IHome } from './Home.d';
import style from './style.scss';

const userStore = namespace<IVSFUser>(UserKey);
const appContextStore = namespace<AppContextStore>(AppContextKey);

interface ICmsPageMixin {
  pageData: CmsPageData;
}

@Component({
  mixins: [CmsPageMixin],
})
export class Home extends BaseComponent<IHome, ICmsPageMixin> {
  protected static T = {
    homeTitle: 'home_title',
  };

  @userStore.Getter('isLoggedIn')
  loggedIn: IVSFUser['state']['current'];

  @appContextStore.Getter('isMobile')
  isMobile: boolean;

  @Watch('loggedIn')
  isLoggedIn() {
    const redirectObj = localStorage.getItem('redirect');

    if (redirectObj) {
      this.$router.push(redirectObj);
    }

    localStorage.removeItem('redirect');
  }

  created() {
    // Load personal and shipping details for Checkout page from IndexedDB
    this.$store.dispatch('checkout/load');
  }

  beforeMount() {
    if (!this.loggedIn && localStorage.getItem('redirect')) {
      this.broadcast('modal-show', 'modal-signup');
      this.$store.commit('myAccount/setDrawerActive', true);
      this.$store.commit('ui/setOverlay', true);
    }
  }

  render() {
    const config = this.extended.$config.zento.images;
    const configCounter = this.extended.$config.zento.theme.countdown;
    const page = this.extended.pageData;

    return (
      <div class={style.homePage}>
        {configCounter.enableWidgetCountdown && !configCounter.enableTopCountdown ? (
          <Countdown widgetStyle={true} />
        ) : null}

        {(page?.content || []).map((items) => {
          const layout = items.meta.layout;
          const heading = items.meta.title.value;
          const heading_position = items.meta.titlePosition;
          const background = items.meta.background;
          const backgroundImg = items.meta.backgroundImage;
          const border = items.meta.divider;
          const paralax = items.meta.paralax;
          const width = items.meta.width;

          return (
            <section class={style.sections}>
              <section
                class={{
                  [style.sectionBox]: true,
                  [style.container]: layout[1],
                  [style.banners]:
                    (config.staticMainBanner.layout === 'layout2' && layout[3]) ||
                    (config.staticMainBanner.layout === 'layout9' && layout[3]), // TODO: This needs to be change once page builder widgets are done
                  [style.customWidgetsWrapper]: config.listCategoriesLarge.layout === 'layout3' && layout[1], // TODO: This needs to be change once page builder widgets are done
                  [style.eventsWrapper]:
                    config.pageEventWidget.layout === 'layout1' &&
                    items.children.length > 1 &&
                    items.children.slice(0, -1).every((item) => item.type === 'page_link'), // TODO: This needs to be change once page builder widgets are done
                  [style.eventsWrapperBtn]:
                    config.pageEventWidget.layout === 'layout1' &&
                    items.children.length > 1 &&
                    items.children[items.children.length - 1].type === 'textBlock', // TODO: This needs to be change once page builder widgets are done
                }}>
                {layout.length > 1 && items.meta.title.value ? (
                  <div
                    class={{
                      [style.titleWidget]: true,
                      [style.centerTitle]: heading_position === 'center',
                      [style.rightTitle]: heading_position === 'right',
                    }}>
                    <h2>{items.meta.title.value}</h2>
                  </div>
                ) : null}

                {items.children.map((item, i) => {
                  return item.type === 'slider' ? (
                    <OverwriteStyle
                      overwrite={{
                        backgroundImg,
                        background: background ?? (item.data as any).backgroundColor,
                        border,
                        paralax,
                      }}>
                      <section
                        style={{
                          width: !this.isMobile ? layout[i] + '%' : '',
                        }}>
                        <HomepageSliderBanner
                          sliderHeroData={{
                            ...(item.data as SliderBannerData),
                            heading: layout.length === 1 ? (heading !== '' ? heading : '') : '',
                            heading_position: heading_position && heading_position !== '' ? heading_position : '',
                            width,
                          }}
                        />
                      </section>
                    </OverwriteStyle>
                  ) : item.type === 'heroImage' ? (
                    <OverwriteStyle
                      overwrite={{
                        backgroundImg,
                        background: background ?? (item.data as any).backgroundColor,
                        border,
                        paralax,
                      }}>
                      <section style={{ width: !this.isMobile ? layout[i] + '%' : '' }}>
                        <HomepageStaticBanner
                          bannerHeroData={{ ...(item.data as BannerHeroImage), width }}
                          layoutItems={layout}
                        />
                      </section>
                    </OverwriteStyle>
                  ) : item.type === 'banner' ? (
                    <OverwriteStyle
                      overwrite={{
                        backgroundImg,
                        background: background ?? (item.data as any).backgroundColor,
                        border,
                        paralax,
                      }}>
                      <section
                        style={{
                          width: !this.isMobile ? layout[i] + '%' : '',
                        }}>
                        <HomepageMainBanner bannerData={item.data as BannerData[]} componentWidth={width} />
                      </section>
                    </OverwriteStyle>
                  ) : item.type === 'products' || item.type === 'newProducts' || item.type === 'bestSellers' ? (
                    <OverwriteStyle
                      overwrite={{
                        backgroundImg,
                        background: background ?? (item.data as any).backgroundColor,
                        border,
                        paralax,
                      }}>
                      <section
                        style={{
                          width: !this.isMobile ? layout[i] + '%' : '',
                        }}>
                        <HomepageProducts
                          data={{
                            ...(item.data as ProductsData),
                            type: item.type,
                            width,
                            heading:
                              layout.length === 1
                                ? heading !== ''
                                  ? heading
                                  : (item.data as ProductsData).heading
                                : '',
                            heading_position:
                              heading_position && heading_position !== ''
                                ? heading_position
                                : (item.data as ProductsData).heading_position,
                          }}
                        />
                      </section>
                    </OverwriteStyle>
                  ) : (item.data as ShopByCategoriesData).listType === 'category1' ? (
                    <OverwriteStyle
                      overwrite={{
                        backgroundImg,
                        background: background ?? (item.data as any).backgroundColor,
                        border,
                        paralax,
                      }}>
                      <section style={{ width: !this.isMobile ? layout[i] + '%' : '' }}>
                        <HomepageCategoriesList
                          categories={{
                            ...(item.data as ShopByCategoriesData),
                            width,
                            heading:
                              layout.length === 1
                                ? heading !== ''
                                  ? heading
                                  : (item.data as ShopByCategoriesData).heading
                                : '',
                            heading_position:
                              heading_position && heading_position !== ''
                                ? heading_position
                                : (item.data as ShopByCategoriesData).heading_position,
                          }}
                        />
                      </section>
                    </OverwriteStyle>
                  ) : (item.data as ShopByCategoriesData).listType === 'category2' ||
                    (item.data as ShopByCategoriesData).listType === 'category6' ? (
                    <OverwriteStyle
                      overwrite={{
                        backgroundImg,
                        background: background ?? (item.data as any).backgroundColor,
                        border,
                        paralax,
                      }}
                      class={{
                        [style.categories]: config.secondListCategories.layout === 'layout1',
                      }}>
                      <section style={{ width: !this.isMobile ? layout[i] + '%' : '' }}>
                        <HomepageCategoriesListImage
                          categories={{
                            ...(item.data as ShopByCategoriesData),
                            width,
                            heading:
                              layout.length === 1
                                ? heading !== ''
                                  ? heading
                                  : (item.data as ShopByCategoriesData).heading
                                : '',
                            heading_position:
                              heading_position && heading_position !== ''
                                ? heading_position
                                : (item.data as ShopByCategoriesData).heading_position,
                          }}
                        />
                      </section>
                    </OverwriteStyle>
                  ) : (item.data as ShopByCategoriesData).listType === 'category3' ? (
                    <OverwriteStyle
                      overwrite={{
                        backgroundImg,
                        background: background ?? (item.data as any).backgroundColor,
                        border,
                        paralax,
                      }}>
                      <section style={{ width: !this.isMobile ? layout[i] + '%' : '' }}>
                        <HomepageCategoriesBanner
                          categories={{
                            ...(item.data as ShopByCategoriesData),
                            width,
                            heading:
                              layout.length === 1
                                ? heading !== ''
                                  ? heading
                                  : (item.data as ShopByCategoriesData).heading
                                : '',
                            heading_position:
                              heading_position && heading_position !== ''
                                ? heading_position
                                : (item.data as ShopByCategoriesData).heading_position,
                          }}
                        />
                      </section>
                    </OverwriteStyle>
                  ) : (item.data as ShopByCategoriesData).listType === 'category4' ? (
                    <OverwriteStyle
                      overwrite={{
                        backgroundImg,
                        background: background ?? (item.data as any).backgroundColor,
                        border,
                        paralax,
                      }}
                      class={{
                        [style.categories]: config.secondListCategories.layout === 'layout1',
                      }}>
                      <section style={{ width: !this.isMobile ? layout[i] + '%' : '' }}>
                        <HomepageShopByCategories
                          categories={{
                            ...(item.data as ShopByCategoriesData),
                            width,
                            heading:
                              layout.length === 1
                                ? heading !== ''
                                  ? heading
                                  : (item.data as ShopByCategoriesData).heading
                                : '',
                            heading_position:
                              heading_position && heading_position !== ''
                                ? heading_position
                                : (item.data as ShopByCategoriesData).heading_position,
                          }}
                        />
                      </section>
                    </OverwriteStyle>
                  ) : (item.data as ShopByCategoriesData).listType === 'category5' ? (
                    <OverwriteStyle
                      overwrite={{
                        backgroundImg,
                        background: background ?? (item.data as any).backgroundColor,
                        border,
                        paralax,
                      }}>
                      <section style={{ width: !this.isMobile ? layout[i] + '%' : '' }}>
                        <HomepageCategoriesListImageLarge
                          categories={{
                            ...(item.data as ShopByCategoriesData),
                            width,
                            heading:
                              layout.length === 1
                                ? heading !== ''
                                  ? heading
                                  : (item.data as ShopByCategoriesData).heading
                                : '',
                            heading_position:
                              heading_position && heading_position !== ''
                                ? heading_position
                                : (item.data as ShopByCategoriesData).heading_position,
                          }}
                        />
                      </section>
                    </OverwriteStyle>
                  ) : item.type === 'blog_posts' ? (
                    <OverwriteStyle
                      overwrite={{
                        backgroundImg,
                        background: background ?? (item.data as any).backgroundColor,
                        border,
                        paralax,
                      }}>
                      <section style={{ width: !this.isMobile ? layout[i] + '%' : '' }}>
                        <BlogWidget
                          data={{
                            ...(item.data as BlogArticlesData),
                            width,
                            heading:
                              layout.length === 1
                                ? heading !== ''
                                  ? heading
                                  : (item.data as BlogArticlesData).heading
                                : '',
                            heading_position:
                              heading_position && heading_position !== ''
                                ? heading_position
                                : (item.data as BlogArticlesData).heading_position,
                          }}
                        />
                      </section>
                    </OverwriteStyle>
                  ) : item.type === 'blog_categories' ? (
                    <OverwriteStyle
                      overwrite={{
                        backgroundImg,
                        background: background ?? (item.data as any).backgroundColor,
                        border,
                        paralax,
                      }}>
                      <section style={{ width: !this.isMobile ? layout[i] + '%' : '' }}>
                        <BlogCategories
                          data={{
                            ...(item.data as BlogCategoriesData),
                            width,
                            heading:
                              layout.length === 1
                                ? heading !== ''
                                  ? heading
                                  : (item.data as BlogCategoriesData).heading
                                : '',
                            heading_position:
                              heading_position && heading_position !== ''
                                ? heading_position
                                : (item.data as BlogCategoriesData).heading_position,
                          }}
                        />
                      </section>
                    </OverwriteStyle>
                  ) : item.type === 'cmsBlock' ? (
                    <OverwriteStyle
                      overwrite={{
                        backgroundImg,
                        background: background ?? (item.data as any).backgroundColor,
                        border,
                        paralax,
                      }}>
                      <section style={{ width: !this.isMobile ? layout[i] + '%' : '' }}>
                        <CmsBlock
                          class={{
                            [style.widgetContent]: true,
                            [style.containerSmall]: width === 'small',
                            [style.largeContainer]: width === 'large',
                            [style.fullContainer]: width === 'full_width',
                          }}
                          identifier={(item.data as CmsBlockData).identifier || ''}
                          content={(item.data as CmsBlockData).content || ''}
                          heading={layout.length === 1 ? (heading !== '' ? heading : '') : ''}
                          headingPosition={heading_position && heading_position !== '' ? heading_position : ''}
                        />
                      </section>
                    </OverwriteStyle>
                  ) : item.type === 'textBlock' ? (
                    <OverwriteStyle
                      overwrite={{
                        backgroundImg,
                        background: background ?? (item.data as any).backgroundColor,
                        border,
                        paralax,
                      }}
                      class={{
                        [style.textBlock]: config.secondListCategories.layout === 'layout1',
                      }}>
                      <section style={{ width: !this.isMobile ? layout[i] + '%' : '' }}>
                        <TextBlock
                          class={{
                            [style.widgetContent]: true,
                            [style.containerSmall]: width === 'small',
                            [style.largeContainer]: width === 'large',
                            [style.fullContainer]: width === 'full_width',
                          }}
                          textBlockData={{
                            ...(item.data as TextBlockData),
                            width,
                            heading:
                              layout.length === 1
                                ? heading !== ''
                                  ? heading
                                  : (item.data as TextBlockData).heading
                                : '',
                            heading_position:
                              heading_position && heading_position !== ''
                                ? heading_position
                                : (item.data as TextBlockData).heading_position,
                          }}
                        />
                      </section>
                    </OverwriteStyle>
                  ) : item.type === 'usp' ? (
                    <OverwriteStyle
                      overwrite={{
                        backgroundImg,
                        background: background ?? (item.data as any).backgroundColor,
                        border,
                        paralax,
                      }}>
                      <section
                        style={{ width: !this.isMobile ? layout[i] + '%' : '' }}
                        data-usp={this.extended.$config.zento.home.usp}>
                        <HomePageUsp
                          uspData={{
                            ...(item.data as Usp),
                            width,
                            heading: layout.length === 1 ? (heading !== '' ? heading : '') : '',
                            heading_position:
                              heading_position && heading_position !== ''
                                ? heading_position
                                : (item.data as Usp).heading_position,
                          }}
                        />
                      </section>
                    </OverwriteStyle>
                  ) : item.type === 'form' ? (
                    <OverwriteStyle
                      overwrite={{
                        backgroundImg,
                        background: background ?? (item.data as any).backgroundColor,
                        border,
                        paralax,
                      }}>
                      <section style={{ width: !this.isMobile ? layout[i] + '%' : '' }}>
                        <CustomForms formData={{ ...(item.data as FormData), width }} />
                      </section>
                    </OverwriteStyle>
                  ) : item.type === 'video' ? (
                    <OverwriteStyle
                      overwrite={{
                        backgroundImg,
                        background: background ?? (item.data as any).backgroundColor,
                        border,
                        paralax,
                      }}>
                      <section style={{ width: !this.isMobile ? layout[i] + '%' : '' }}>
                        <VideoWidget
                          videoWidgetData={{
                            ...(item.data as VideoWidgetData),
                            width,
                            heading:
                              layout.length === 1
                                ? heading !== ''
                                  ? heading
                                  : (item.data as VideoWidgetData).heading
                                : '',
                            heading_position:
                              heading_position && heading_position !== ''
                                ? heading_position
                                : (item.data as VideoWidgetData).heading_position,
                          }}
                        />
                      </section>
                    </OverwriteStyle>
                  ) : item.type === 'page_link' ? (
                    <OverwriteStyle
                      overwrite={{
                        backgroundImg,
                        background: background ?? (item.data as any).backgroundColor,
                        border,
                        paralax,
                      }}>
                      <section style={{ width: !this.isMobile ? layout[i] + '%' : '' }}>
                        <PageLinkWidget
                          pageWidgetData={{
                            ...(item.data as PageWidgetData),
                            width,
                            heading:
                              layout.length === 1
                                ? heading !== ''
                                  ? heading
                                  : (item.data as PageWidgetData).heading
                                : '',
                            heading_position:
                              heading_position && heading_position !== ''
                                ? heading_position
                                : (item.data as PageWidgetData).heading_position,
                            events:
                              config.pageEventWidget.layout === 'layout1' &&
                              items.children.length > 1 &&
                              items.children.slice(0, -1).every((item) => item.type === 'page_link'),
                          }}
                        />
                      </section>
                    </OverwriteStyle>
                  ) : item.type === 'page_links' ? (
                    <OverwriteStyle
                      overwrite={{
                        backgroundImg,
                        background: background ?? (item.data as any).backgroundColor,
                        border,
                        paralax,
                      }}>
                      <section style={{ width: !this.isMobile ? layout[i] + '%' : '' }}>
                        <CmsPages
                          cmsPages={{
                            ...(item.data as PageWidgetData),
                            width,
                            heading:
                              layout.length === 1
                                ? heading !== ''
                                  ? heading
                                  : (item.data as PageWidgetData).heading
                                : '',
                            heading_position:
                              heading_position && heading_position !== ''
                                ? heading_position
                                : (item.data as PageWidgetData).heading_position,
                          }}
                        />
                      </section>
                    </OverwriteStyle>
                  ) : item.type === 'instagram' ? (
                    <OverwriteStyle
                      overwrite={{
                        backgroundImg,
                        background: background ?? (item.data as any).backgroundColor,
                        border,
                        paralax,
                      }}>
                      <section class={style.instagram} style={{ width: !this.isMobile ? layout[i] + '%' : '' }}>
                        <Instagram
                          instagramData={{
                            ...(item.data as InstagramData),
                            width,
                            heading:
                              layout.length === 1
                                ? heading !== ''
                                  ? heading
                                  : (item.data as InstagramData).heading
                                : '',
                            heading_position:
                              heading_position && heading_position !== ''
                                ? heading_position
                                : (item.data as InstagramData).heading_position,
                          }}
                        />
                      </section>
                    </OverwriteStyle>
                  ) : item.type === 'testimonial' ? (
                    <OverwriteStyle
                      overwrite={{
                        backgroundImg,
                        background: background ?? (item.data as any).backgroundColor,
                        border,
                        paralax,
                      }}>
                      <section style={{ width: !this.isMobile ? layout[i] + '%' : '' }}>
                        <Testimonials
                          testimonialsData={{
                            ...(item.data as TestimonialsData),
                            width,
                            heading:
                              layout.length === 1
                                ? heading !== ''
                                  ? heading
                                  : (item.data as TestimonialsData).heading
                                : '',
                            heading_position:
                              heading_position && heading_position !== ''
                                ? heading_position
                                : (item.data as TestimonialsData).heading_position,
                          }}
                        />
                      </section>
                    </OverwriteStyle>
                  ) : null;
                })}
              </section>
            </section>
          );
        })}
      </div>
    );
  }
}
